import { Component, Vue } from 'vue-property-decorator'
import { diabeteType } from '../../Dossier/Constants'
import { ValidationObserver } from 'vee-validate'
import { PatientService } from '@/services/patient-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { Subscription } from 'rxjs'
import { AnamneseSuiviModel } from '@/models/anamneseSuivi-model'
import { ErrorService } from '@/services/error.service'
import { AxiosResponse } from 'axios'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    Alert,
    Confirm,
    ValidationObserver
  }
})
export default class Infirmier extends Vue {
  private patientService = PatientService.getInstance()
  private subscriptions: Subscription[] = []
  public isLoading = false
  public isSuiviSaving = false
  public initLoading = false
  public showDialog = false
  public showConfirm = false
  public diabTypes = diabeteType
  public anamnese: any = {}
  public anamneseSuiviItem: AnamneseSuiviModel | any = {}
  public medicaments = []
  public medications: AnamneseSuiviModel[] = []
  public errorMessages: string[] = []
  public selectedDossier!: dossierPatientModel

  public initialAnamneseValue: any = {}

  public mounted () {
    this.subscriptions.push(this.patientService.dossierSelected$.subscribe((dossier: dossierPatientModel) => {
      this.selectedDossier = dossier
      this.ensureUserNavTitle()
    }))
    this.GetAllSuivis()
    this.patientService.GetAllMedicines().then((meds: any) => {
      this.medicaments = meds
    })
  }

  public GetAllSuivis () {
    this.initLoading = true
    this.patientService.getDossierAnamnese(this.selectedDossier.guid)
      .then((dossierAnamnese: any) => {
        this.anamnese = dossierAnamnese ?? {}
        this.initialAnamneseValue = { ...this.anamnese }
        this.medications = this.anamnese.suiviInfirmierItems
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.initLoading = false
      })
  }

  public ensureUserNavTitle () {
    if (this.selectedDossier?.guid) {
      Commons.updateDossierName()
    }
  }

  public isDateActive (dateEnd: string) {
    return Commons.isDateActive(dateEnd)
  }

  public async save () {
    const observer = this.$refs.mainObserver as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isLoading = true
      this.patientService.AddDossierAnamnese(this.selectedDossier.guid, this.anamnese)
        .then(() => {
          this.$emit('suiviSaved')
          this.GetAllSuivis()
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.initialAnamneseValue = { ...this.anamnese }
          this.isLoading = false
        })
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public async saveAnamneseSuiviItem () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSuiviSaving = true
      this.errorMessages = []
      this.unboxMedicine(this.anamneseSuiviItem)
      const results = await this.patientService.AddDossierAnamneseSuivi(this.selectedDossier.guid, this.anamneseSuiviItem)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSuiviSaving = false
        })
      if ((results as AxiosResponse<any>)?.status === 200) {
        this.resetdialog()
        this.showDialog = false
        this.GetAllSuivis()
      }
    } else {
      const merged = Object.assign(this.$refs, (this.$refs.DateRef as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  public editSuiviItem (item: AnamneseSuiviModel) {
    this.anamneseSuiviItem = this.boxMedicine(item)
    this.showDialog = true
  }

  private unboxMedicine (anamneseSuiviItem: AnamneseSuiviModel) {
    if (typeof anamneseSuiviItem.medicine === 'string') {
      anamneseSuiviItem.medicineName = anamneseSuiviItem.medicine
    } else {
      anamneseSuiviItem.medicineId = anamneseSuiviItem.medicine.medId
      anamneseSuiviItem.medicineName = anamneseSuiviItem.medicine.medDesc
    }
  }

  private boxMedicine (anamneseSuiviItem: AnamneseSuiviModel) {
    const item = { ...anamneseSuiviItem }
    if (item.medicineId) {
      item.medicine = item.medicineName as string
    } else {
      item.medicine = { medId: item.medicineId as number, medDesc: item.medicineName as string }
    }
    return item
  }

  public confirmSuiviItemDelete (suivitem: AnamneseSuiviModel) {
    this.showConfirm = true
    this.anamneseSuiviItem = suivitem
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.initLoading = true
      const results = await this.patientService.DeleteDossierAnamneseSuivi((this.anamneseSuiviItem as AnamneseSuiviModel).id as string)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.initLoading = false
        })
      if ((results as AxiosResponse<any>)?.status === 200) {
        this.errorMessages = []
        this.GetAllSuivis()
      }
    }
    this.resetdialog()
  }

  private resetdialog () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    if (observer) observer.reset()
    this.anamneseSuiviItem = {}
  }

  public closeAnamneseDialog () {
    this.errorMessages = []
    this.resetdialog()
    this.showDialog = false
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public $destroyed () {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe()
    })
  }

  public onSuiviChanged () {
    if (this.anamnese.remark !== this.initialAnamneseValue.remark ||
      this.anamnese.pathology !== this.initialAnamneseValue.pathology ||
      this.anamnese.prevMedicines !== this.initialAnamneseValue.prevMedicines ||
      !this.arraysHaveSameElements(this.anamnese.diabeteType, this.initialAnamneseValue.diabeteType)) {
      this.$emit('suiviChanged')
    }
  }

  private arraysHaveSameElements (arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false
    }

    return arr1.every(item => arr2.includes(item))
  }
}
