import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { PatientService } from '@/services/patient-service'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { AxiosResponse } from 'axios'
import { Subscription } from 'rxjs'
import { dossierPatientModel } from '@/models/dossier-response-model'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { AuthService } from '@/services/auth-service'
import { PsychosocialAdviceModel, AdviceTypeEnum } from '@/models/psychosocial-model'
import { adviceType } from '../../Dossier/Constants'
import Commons from '@/components/shared/Helpers/commons'

@Component({
  components: {
    ValidationObserver,
    Alert,
    Confirm
  }
})
export default class Psychosocial extends Vue {
  private patientService = PatientService.getInstance()
  private userService = AuthService.getInstance()
  private subscriptions: Subscription[] = []
  public isLoading = false
  public isSaving = false
  public showDialog = false
  public showConfirm = false
  public anamnese = { dossierId: '', html: '' }
  public advices: PsychosocialAdviceModel[] = []
  public adviceItem: PsychosocialAdviceModel = { date: Commons.GetTodayFormatted() }
  public errorMessages: string[] = []
  public selectedDossier: any = {}
  public suiviTypes = adviceType
  public loggedUser: any = {}
  public userFullName = ''
  public initialAnamneseValue = ''

  public mounted () {
    this.subscriptions.push(this.patientService.dossierSelected$.subscribe((dossier: dossierPatientModel) => {
      this.selectedDossier = dossier
    }))
    if (this.selectedDossier?.guid) {
      this.GetAllAdvices()
      Commons.updateDossierName()
      this.userFullName = this.userService.fullName
    } else {
      this.$router.push('/patient')
    }
  }

  public get getInfoText () {
    if (this.adviceItem.id) {
      return "La saisie d'heures existante sera automatiquement adaptée pour ce conseil."
    }
    return "Une saisie d'heures sera automatiquement créée pour ce conseil."
  }

  public GetAllAdvices () {
    this.patientService.GetDossierPsychosocial(this.selectedDossier.guid)
      .then((dossierPsychosocial: any) => {
        this.anamnese = dossierPsychosocial.anamnese
        this.initialAnamneseValue = this.anamnese.html
        this.advices = dossierPsychosocial.advices
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
  }

  public GetSuiviTypeName (value: number) {
    if (value) {
      return this.suiviTypes.find(x => x.value === value)?.text
    }
    return ''
  }

  public async save () {
    const observer = this.$refs.mainObserver as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isLoading = true
      this.patientService.AddDossierAnamneseHtml(this.selectedDossier.guid, this.anamnese)
        .then(() => {
          this.$emit('anamneseSaved')
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isLoading = false
          this.GetAllAdvices()
        })
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public addNewAdvice () {
    this.adviceItem = { date: Commons.GetTodayFormatted() }
    this.showDialog = true
  }

  public editAdviceItem (item: PsychosocialAdviceModel) {
    this.adviceItem = item
    this.showDialog = true
  }

  public async saveAdviceItem () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      this.errorMessages = []
      const results = await this.patientService.AddDossierAdvice(this.selectedDossier.guid, this.adviceItem)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if ((results as AxiosResponse<any>)?.status === 200) {
        this.resetdialog()
        this.showDialog = false
        this.GetAllAdvices()
      }
    } else {
      const merged = Object.assign(this.$refs, (this.$refs.rdvRef as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  public canDeleteItem (item: PsychosocialAdviceModel) {
    return this.userService.Roles.includes('NursingRecordsDelete') || Commons.GetDate(item.date)! >= Commons.TodayDate()
  }

  public confirmAdviceItemDelete (item: PsychosocialAdviceModel) {
    this.showConfirm = true
    this.adviceItem = item
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      const results = await this.patientService.DeleteDossierAdvice((this.adviceItem as PsychosocialAdviceModel).id as string)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
      if ((results as AxiosResponse<any>)?.status === 200) {
        this.errorMessages = []
        this.GetAllAdvices()
      }
    }
    this.resetdialog()
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public isEmptyGuid (guidId: string) {
    if (guidId?.length === 0) return true
    return guidId.replaceAll('-', '').replaceAll('0', '').trim().length === 0
  }

  public closeAdviceDialog () {
    this.errorMessages = []
    this.resetdialog()
    this.showDialog = false
  }

  private resetdialog () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    if (observer) observer.reset()
    this.adviceItem = { date: Commons.GetTodayFormatted() }
  }

  public $destroyed () {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe()
    })
  }

  public typeChanged (type: AdviceTypeEnum) {
    if (type === AdviceTypeEnum.Evaluation) {
      this.adviceItem.observationDate = this.adviceItem.date
    } else {
      this.adviceItem.observationDate = undefined
    }
  }

  public addEvaluationItem (item: PsychosocialAdviceModel) {
    this.adviceItem = item
    this.adviceItem = {
      date: Commons.GetTodayFormatted(),
      type: 2,
      observationDate: item.date
    }
    this.showDialog = true
  }

  public onAnamneseChanged () {
    if (this.initialAnamneseValue !== this.anamnese.html) {
      this.$emit('anamneseChanged')
    }
  }
}
