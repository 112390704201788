export interface PsychosocialAdviceModel {
    id?: string;
    patientId?: string;
    date: string;
    duration?: number;
    type?: AdviceTypeEnum;
    advice?: string;
    typeStr?: string;
    createdByUserId?: string;
    createdBy?: string;
    observationDate?: string;
  }

export enum AdviceTypeEnum {
    Observation = 1,
    Evaluation
  }

export interface PsychosocialAnamnesisModel {
    id?: string;
    patientId?: string;
    anamnesis?: string;
  }
